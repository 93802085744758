<ng-container *transloco="let t">
  <div class="flex w-full flex-col">
    @if (label) {
      <label [for]="controlName" class="mb-1 text-sm font-medium">{{
        label
      }}</label>
    }
    <input
      [id]="controlName"
      [formControl]="control"
      [placeholder]="placeholder"
      [type]="type"
      class="rounded-lg border border-grey-midGrey p-2 placeholder:text-grey"
    />
    @if (inputHint) {
      <p class="text-sm text-slate-400">{{ inputHint }}</p>
    }
    @if (control.dirty && control.errors && control.value.length > 2) {
      @for (key of controlErrorsToArray(control.errors); track key) {
        <p class="mt-1 text-sm text-red-300">
          {{ t('general.inputValidation.' + key) }}
        </p>
      }
    }
  </div>
</ng-container>
